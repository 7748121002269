import http from './Api';

class CustomersApi {
  static getCustomersHeadCount() {
    return http.get('/customers-headcount');
  }

  static getCustomersHeadCountTopFive() {
    return http.get('/customers-headcount/top-five');
  }

  static customersFileDownload(type) {
    return http.get(`/customers-headcount/download/${type}`);
  }

  static getHeadcountData(year, client) {
    return http.get(`/clients/tenant/${client}/headcount/${year}`);
  }

  static getCustomerInvoices(id, params) {
    return http.get(`/invoices/${id}/invoices`, { params });
  }

  static downloadInvoice(customer, invoice, action = 'preview') {
    return http.get(`/invoices/${invoice}/pdf?inline=`, {
      inline: action === 'preview' ? 1 : 0,
    });
  }

  static generateProration(customer, data) {
    return http.post(`/unbilled-charges/${customer}/unbilled-charges`, data);
  }

  static markAsPaid(customer, id) {
    return http.post(`/invoices/${id}/mark-paid`);
  }

  static deleteInvoice(customer, id) {
    return http.delete(`/invoices/${id}/delete`);
  }

  static getUnbilledCharges(customer, params) {
    return http.get(`unbilled-charges/${customer}`, { params });
  }

  static deleteUnbilledCharge(customer, id) {
    return http.post(`unbilled-charges/${customer}/delete-unbilled-charges/${id}`);
  }

  static generateInvoice(customer) {
    return http.post(`/clients/tenant/${customer}/invoice-unbilled-charges`);
  }
}

export default CustomersApi;
