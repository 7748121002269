export const getSubscriptionPricingModels = (isSingleCharge, pricingUnit, pricingModel, defaultBillingPeriod) => {
  if (!isSingleCharge) {
    if (pricingModel === 'PER_UNIT') {
      return `${pricingUnit.toLowerCase()} ${defaultBillingPeriod ? `/ ${defaultBillingPeriod?.toLowerCase()}` : ''}`;
    }
    if (pricingModel === 'FLAT_FEE') {
      return `per ${defaultBillingPeriod?.toLowerCase()}`;
    }
  }
  if (isSingleCharge) {
    return `per ${pricingUnit.toLowerCase()}`;
  }
  return '';
};
